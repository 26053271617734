exports.components = {
  "component---src-components-article-page-index-tsx": () => import("./../../../src/components/ArticlePage/index.tsx" /* webpackChunkName: "component---src-components-article-page-index-tsx" */),
  "component---src-components-guide-page-index-tsx": () => import("./../../../src/components/GuidePage/index.tsx" /* webpackChunkName: "component---src-components-guide-page-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-ways-digital-signage-software-can-boost-your-business-tsx": () => import("./../../../src/pages/5-ways-digital-signage-software-can-boost-your-business.tsx" /* webpackChunkName: "component---src-pages-5-ways-digital-signage-software-can-boost-your-business-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-autoboot-jsx": () => import("./../../../src/pages/autoboot.jsx" /* webpackChunkName: "component---src-pages-autoboot-jsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blueprint-for-hiring-a-graphic-designer-tsx": () => import("./../../../src/pages/blueprint-for-hiring-a-graphic-designer.tsx" /* webpackChunkName: "component---src-pages-blueprint-for-hiring-a-graphic-designer-tsx" */),
  "component---src-pages-church-tsx": () => import("./../../../src/pages/church.tsx" /* webpackChunkName: "component---src-pages-church-tsx" */),
  "component---src-pages-corporate-environments-tsx": () => import("./../../../src/pages/corporate-environments.tsx" /* webpackChunkName: "component---src-pages-corporate-environments-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-examples-jsx": () => import("./../../../src/pages/examples.jsx" /* webpackChunkName: "component---src-pages-examples-jsx" */),
  "component---src-pages-features-index-jsx": () => import("./../../../src/pages/features/index.jsx" /* webpackChunkName: "component---src-pages-features-index-jsx" */),
  "component---src-pages-features-powerful-scheduling-index-tsx": () => import("./../../../src/pages/features/powerful-scheduling/index.tsx" /* webpackChunkName: "component---src-pages-features-powerful-scheduling-index-tsx" */),
  "component---src-pages-features-screen-previews-index-tsx": () => import("./../../../src/pages/features/screen-previews/index.tsx" /* webpackChunkName: "component---src-pages-features-screen-previews-index-tsx" */),
  "component---src-pages-features-templates-index-tsx": () => import("./../../../src/pages/features/templates/index.tsx" /* webpackChunkName: "component---src-pages-features-templates-index-tsx" */),
  "component---src-pages-guides-tsx": () => import("./../../../src/pages/guides.tsx" /* webpackChunkName: "component---src-pages-guides-tsx" */),
  "component---src-pages-gyms-tsx": () => import("./../../../src/pages/gyms.tsx" /* webpackChunkName: "component---src-pages-gyms-tsx" */),
  "component---src-pages-hardware-amazon-fire-tv-index-tsx": () => import("./../../../src/pages/hardware/amazon-fire-tv/index.tsx" /* webpackChunkName: "component---src-pages-hardware-amazon-fire-tv-index-tsx" */),
  "component---src-pages-hardware-index-tsx": () => import("./../../../src/pages/hardware/index.tsx" /* webpackChunkName: "component---src-pages-hardware-index-tsx" */),
  "component---src-pages-hardware-web-browser-tsx": () => import("./../../../src/pages/hardware/web-browser.tsx" /* webpackChunkName: "component---src-pages-hardware-web-browser-tsx" */),
  "component---src-pages-healthcare-tsx": () => import("./../../../src/pages/healthcare.tsx" /* webpackChunkName: "component---src-pages-healthcare-tsx" */),
  "component---src-pages-hospitality-tsx": () => import("./../../../src/pages/hospitality.tsx" /* webpackChunkName: "component---src-pages-hospitality-tsx" */),
  "component---src-pages-how-it-works-jsx": () => import("./../../../src/pages/how-it-works.jsx" /* webpackChunkName: "component---src-pages-how-it-works-jsx" */),
  "component---src-pages-how-to-pair-a-screen-tsx": () => import("./../../../src/pages/how-to-pair-a-screen.tsx" /* webpackChunkName: "component---src-pages-how-to-pair-a-screen-tsx" */),
  "component---src-pages-howtoredeem-tsx": () => import("./../../../src/pages/howtoredeem.tsx" /* webpackChunkName: "component---src-pages-howtoredeem-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-church-tsx": () => import("./../../../src/pages/industries/church.tsx" /* webpackChunkName: "component---src-pages-industries-church-tsx" */),
  "component---src-pages-industries-corporate-environments-tsx": () => import("./../../../src/pages/industries/corporate-environments.tsx" /* webpackChunkName: "component---src-pages-industries-corporate-environments-tsx" */),
  "component---src-pages-industries-education-tsx": () => import("./../../../src/pages/industries/education.tsx" /* webpackChunkName: "component---src-pages-industries-education-tsx" */),
  "component---src-pages-industries-events-tsx": () => import("./../../../src/pages/industries/events.tsx" /* webpackChunkName: "component---src-pages-industries-events-tsx" */),
  "component---src-pages-industries-gyms-tsx": () => import("./../../../src/pages/industries/gyms.tsx" /* webpackChunkName: "component---src-pages-industries-gyms-tsx" */),
  "component---src-pages-industries-healthcare-tsx": () => import("./../../../src/pages/industries/healthcare.tsx" /* webpackChunkName: "component---src-pages-industries-healthcare-tsx" */),
  "component---src-pages-industries-hospitality-tsx": () => import("./../../../src/pages/industries/hospitality.tsx" /* webpackChunkName: "component---src-pages-industries-hospitality-tsx" */),
  "component---src-pages-industries-museums-tsx": () => import("./../../../src/pages/industries/museums.tsx" /* webpackChunkName: "component---src-pages-industries-museums-tsx" */),
  "component---src-pages-industries-outdoor-advertising-tsx": () => import("./../../../src/pages/industries/outdoor-advertising.tsx" /* webpackChunkName: "component---src-pages-industries-outdoor-advertising-tsx" */),
  "component---src-pages-industries-real-estate-tsx": () => import("./../../../src/pages/industries/real-estate.tsx" /* webpackChunkName: "component---src-pages-industries-real-estate-tsx" */),
  "component---src-pages-industries-transportation-tsx": () => import("./../../../src/pages/industries/transportation.tsx" /* webpackChunkName: "component---src-pages-industries-transportation-tsx" */),
  "component---src-pages-museums-tsx": () => import("./../../../src/pages/museums.tsx" /* webpackChunkName: "component---src-pages-museums-tsx" */),
  "component---src-pages-new-release-12-oct-2022-md-tsx": () => import("./../../../src/pages/new-release-12-oct-2022.md.tsx" /* webpackChunkName: "component---src-pages-new-release-12-oct-2022-md-tsx" */),
  "component---src-pages-outdoor-advertising-tsx": () => import("./../../../src/pages/outdoor-advertising.tsx" /* webpackChunkName: "component---src-pages-outdoor-advertising-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-real-estate-tsx": () => import("./../../../src/pages/real-estate.tsx" /* webpackChunkName: "component---src-pages-real-estate-tsx" */),
  "component---src-pages-restaurants-cafes-and-bars-tsx": () => import("./../../../src/pages/restaurants-cafes-and-bars.tsx" /* webpackChunkName: "component---src-pages-restaurants-cafes-and-bars-tsx" */),
  "component---src-pages-retail-tsx": () => import("./../../../src/pages/retail.tsx" /* webpackChunkName: "component---src-pages-retail-tsx" */),
  "component---src-pages-setup-an-office-dashboard-basic-scheduling-tsx": () => import("./../../../src/pages/setup-an-office-dashboard-(basic-scheduling).tsx" /* webpackChunkName: "component---src-pages-setup-an-office-dashboard-basic-scheduling-tsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-supported-hardware-jsx": () => import("./../../../src/pages/supported-hardware.jsx" /* webpackChunkName: "component---src-pages-supported-hardware-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-transportation-tsx": () => import("./../../../src/pages/transportation.tsx" /* webpackChunkName: "component---src-pages-transportation-tsx" */),
  "component---src-pages-upsell-with-digital-signage-in-your-cafe-tsx": () => import("./../../../src/pages/upsell-with-digital-signage-in-your-cafe.tsx" /* webpackChunkName: "component---src-pages-upsell-with-digital-signage-in-your-cafe-tsx" */),
  "component---src-pages-why-a-digital-menu-board-is-a-good-idea-tsx": () => import("./../../../src/pages/why-a-digital-menu-board-is-a-good-idea.tsx" /* webpackChunkName: "component---src-pages-why-a-digital-menu-board-is-a-good-idea-tsx" */),
  "component---src-pages-why-digital-signage-will-save-you-time-and-money-tsx": () => import("./../../../src/pages/why-digital-signage-will-save-you-time-and-money.tsx" /* webpackChunkName: "component---src-pages-why-digital-signage-will-save-you-time-and-money-tsx" */)
}

